/* LANG: ZH */
/* MASTER FILE */

import { $vuetify } from "./basic-zh/common";
import { title } from "./basic-zh/title";
import { text } from "./basic-zh/text";
import { message } from "./basic-zh/message";
import { hint } from "./basic-zh/hint";

export default {
    $vuetify,
    title,
    text,
    message,
    hint
}
/* BEGIN TRANSLATION */

const hint = {
    format: "%yy / %yyyy - 年， %mm - 月， %dd - 天， %nn - 编号",
    "can-be-multiple": "可多选",
    "token-infinite": "令牌无限期",
    "token-expires": "令牌将于{0}到期"
};

/* ENDED TRANSLATION */
export { hint };
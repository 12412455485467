/* BEGIN TRANSLATION */

const title = {
    app: "直播助手",
    information: "信息",
    "error-occurred": "错误发生",
    "management-login": "管理员登录",
    "running-number": "自动号码",
    "system-setting": "系统设定",
    company: "公司",
    "company-address": "公司地址",
    user: "用户",
    customer: "客户",
    "customer-group": "客户组",
    "discard-changes": "放弃更改",
    "delete-record": "删除记录",
    create: "创造",
    modify: "调整",
    profile: "用户资料",
    address: "地址",
    "new-address": "新地址",
    "edit-address": "修改地址",
    "customer-address": "客户地址",
    "live-session": "直播会话",
    confirmation: "确认",
    "facebook-account": "Facebook账号",
    currency: "货币",
    "session-closing": "会话闭幕",
    "product-sales": "产品销售",
    "import-live-session-detail": "导入直播会话详情",
    "notify-customer": "通知客户",
    "shipping-address": "运送地址",
    checkout: "结账",
    payment: "付款",
    "customer-order": "客户订单",
    "customer-payment": "客户付款",
    attachments: "附件",
    country: "国家",
    state: "州属",
    city: "城市",
    order: "订单",
    "shipping-fee": "运费",
    voucher: "优惠券",
    pricing: "价格",
    "payment-detail": "付款详情",
    "cancel-order": "取消订单",
    "finalize-order": "总结订单",
    "reject-order": "拒绝订单",
    "reverse-order": "撤销订单",
    "transfer-request": "转让请求",
    "finalize-payment": "总结付款",
    "approve-payment": "批准付款",
    "cancel-payment": "取消付款",
    "approve-transfer": "批准转让",
    "cancel-transfer": "取消转让",
    transfer: "转让",
    event: "事件",
    "wallet-balance": "钱包余额",
    "customer-payment-summary": "客户付款概要",
    "upload-receipt": "上传收据",
    postage: "邮资",
    shipment: "运输",
    "expiration-date": "到期于",
    "change-password": "更改密码"
};

/* ENDED TRANSLATION */
export { title };
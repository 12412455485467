/* BEGIN TRANSLATION */

const text = {
    ok: "确定",
    yes: "是",
    no: "否",
    login: "登录",
    cancel: "取消",
    profile: "用户资料",
    logout: "登出",
    "login-with-facebook": "使用Facebook登录",
    settings: "设定",
    username: "用户名",
    password: "密码",
    company: "公司",
    code: "代号",
    name: "名称",
    management: "管理",
    customer: "客户",
    "registration-no": "注册号",
    "tax-registration-no": "税务登记号",
    "discard-changes": "放弃更改",
    "save-changes": "保存更改",
    "delete-record": "删除记录",
    address: "地址",
    "printing-address": "印刷地址",
    actions: "操作",
    close: "关闭",
    submit: "提交",
    line1: "第一行",
    line2: "第二行",
    line3: "第三行",
    line4: "第四行",
    postcode: "邮政编码",
    city: "城市",
    state: "州属",
    country: "国家",
    email: "电子邮件",
    "contact-no": "联系方式",
    "contact-person": "联络人",
    create: "创造",
    modify: "调整",
    "delete-selected": "删除所选",
    "running-number": "自动号码",
    "record-count": "记录数",
    refresh: "刷新",
    delete: "删除",
    format: "格式",
    length: "长度",
    sample: "样本",
    "reset-by": "重置于",
    none: "无",
    daily: "每日",
    monthly: "每月",
    yearly: "每年",
    "change-password": "更改密码",
    "current-password": "当前密码",
    "system-setting": "系统设定",
    "full-name": "全名",
    "facebook-linked": "Facebook已连接",
    user: "用户",
    "new-password": "新密码",
    "confirm-password": "确认密码",
    "create-record": "创建新的",
    "finalize-record": "总结记录",
    birthday: "生日",
    gender: "性别",
    male: "男性",
    female: "女性",
    "customer-group": "客户组",
    "purchase-amount-limit": "购买金额限制",
    "purchase-quantity-limit": "购买数量限制",
    back: "后退",
    "address-book": "地址簿",
    "shipping-address": "运送地址",
    sales: "销售量",
    "live-session": "直播会话",
    "session-date": "会话日期",
    "new-session": "新会话",
    "start-session": "开始会话",
    "end-session": "结束会话",
    status: "状态",
    "live-session-info": "直播会话信息",
    comment: "评论",
    "comment-count": "评论数",
    hide: "隐藏",
    "link-facebook": "与Facebook连接",
    "product-introducing": "产品介绍",
    "product-code": "产品代号",
    "product-name": "产品名称",
    "weight-g": "重量（g）",
    "total-weight-g": "总重量（g）",
    quantity: "数量",
    "available-quantity": "可用数量",
    price: "价格",
    open: "打开",
    ended: "已结束",
    video: "视频",
    "confirmation-dialog": "确认对话框",
    "history-count": "历史计数",
    account: "帐户",
    "facebook-account": "Facebook账号",
    "facebook-url": "Facebook网址",
    link: "连接",
    "started-date": "开始日期",
    "ended-date": "结束日期",
    bidding: "投标",
    remarks: "评论",
    deposit: "订金",
    save: "保存",
    send: "发送",
    "accept-order": "订单已接受",
    ordered: "已接受",
    symbol: "符号",
    common: "基本",
    currency: "货币",
    currencies: "货币",
    "total-amount": "总金额",
    "live-sessions": "直播会话",
    "product-sales": "产品销售",
    customers: "客户",
    "closing-no": "闭幕编号",
    "closing-date": "闭幕日期",
    "session-closing": "直播闭幕",
    "finalized-date": "总结日期",
    "add-live-session": "添加直播会话",
    import: "导入",
    "import-all": "全部导入",
    "line-no": "行",
    "total-quantity": "总数量",
    notify: "通知",
    "notify-all": "通知全部",
    message: "信息",
    notified: "已通知",
    notification: "通知",
    "expires-at": "到期时间",
    "password-enabled": "可用密码",
    summary: "概要",
    subtotal: "小计",
    "shipping-fee": "运费",
    checkout: "结账",
    "payment-method": "付款方式",
    receipt: "收据",
    wallet: "钱包",
    mixed: "混合",
    products: "产品",
    payment: "付款",
    "payment-no": "付款编号",
    "payment-date": "付款日期",
    "login-enabled": "可登录",
    administrator: "管理员",
    "user-info": "用户信息",
    "customer-order": "客户订单",
    "customer-code": "客户代号",
    "customer-name": "客户姓名",
    "pending-amount": "待定金额",
    "customer-payment": "客户付款",
    fullscreen: "全屏",
    description: "描述",
    regions: "地区",
    "all-countries": "所有国家",
    "all-states": "所有州属",
    "all-cities": "所有城市",
    "add-region": "添加地区",
    addresses: "地址",
    "capture-order": "捕获订单",
    "capture-format": "捕获格式",
    "comment-name-color": "评论名称颜色",
    "comment-message-color": "评论信息颜色",
    "comment-background-color": "评论背景色",
    "comment-tag-color": "评论标签颜色",
    voucher: "优惠卷",
    "voucher-length": "优惠券长度",
    "discount-target": "折扣目标",
    "discount-overall": "总体（小计和运费）",
    "discount-amount": "折扣金额",
    "discount-percent": "折扣率",
    "maximum-discount": "最高折扣",
    "minimum-order-amount": "最低订单金额",
    "maximum-order-amount": "最大订单金额",
    "minimum-total-amount": "最低总金额",
    "maximum-total-amount": "最大总金额",
    "payment-amount": "支付金额",
    "repeat-usage": "重复使用",
    discontinued: "已停止",
    pricing: "价格",
    "weight-step-g": "重量增量（g）",
    "standard-delivery": "标准运送",
    "on-hold-delivery": "暂缓运送",
    "store-pickup": "商店提货",
    orders: "订单",
    "receipt-payment": "收据付款",
    "wallet-payment": "钱包付款",
    "total-payment": "总付款",
    details: "详情",
    order: "订单",
    "order-no": "订单号",
    "order-date": "订单日期",
    "customer-order-detail": "客户订单详情",
    draft: "草案",
    "to-pay": "需付款",
    processing: "处理中",
    paid: "已付款",
    delivered: "已运送",
    cancelled: "已取消",
    expired: "已到期",
    rejected: "已拒绝",
    completed: "已完成",
    "cancel-order": "取消订单",
    "finalize-order": "完成订单",
    "reject-order": "拒绝订单",
    "move-record": "移动记录",
    existing: "现存",
    "new-order": "新订单",
    "sub-order": "子订单",
    "new-order-status": "新订单状态",
    "approve-payment": "批准付款",
    "cancel-payment": "取消付款",
    "reject-payment": "拒绝付款",
    "finalize-payment": "总结付款",
    "cash-payment": "现金支付",
    "wallet-balance": "钱包余额",
    refunded: "已退款",
    "select-all": "全选",
    type: "类型",
    "generate-token": "生成令牌",
    token: "令牌",
    miscellaneous: "各样",
    "customer-token-expires-minutes": "客户令牌到期（分钟）",
    never: "绝不",
    copy: "复制",
    "request-no": "请求代号",
    "request-date": "请求日期",
    "request-by-code": "请求于代号",
    "request-by-name": "请求于名称",
    "transfer-to-code": "转让至代号",
    "transfer-to-name": "转让至姓名",
    "transfer-order": "转让订单",
    transfer: "转让",
    "selected-orders": "选定订单",
    "transfer-token": "转让令牌",
    "transfer-request": "转让请求",
    "request-by": "请求于",
    "transfer-to": "转让至",
    "approve-transfer": "批准转让",
    "cancel-transfer": "取消转让",
    view: "查看",
    "date-format": "日期格式",
    "time-format": "时间格式",
    search: "搜索",
    clear: "清除",
    filter: "筛选",
    all: "全部",
    preparing: "准备中",
    started: "已开始",
    closed: "已关闭",
    transferred: "已转让",
    finalized: "已完成",
    select: "选择",
    generate: "生成",
    "print-receipt": "打印收据",
    "date-from": "从日期",
    "date-to": "至日期",
    "empty-date": "空日期",
    "voucher-numbers": "优惠券号码",
    "voucher-number": "优惠券号码",
    "delivery-method": "运送方式",
    approved: "已批准",
    transferring: "转让中",
    "transfer-date": "转让日期",
    "customer-group-event": "客户组事件",
    events: "事件",
    "order-extend-expires": "订单时延长到期（天）",
    "deposit-extend-expires": "存款时延长到期（天）",
    "checkout-extend-expires": "结帐时延长到期（天）",
    "cancelled-extend-expires": "取消时延长到期（天）",
    "order-extend-count": "订单时延长计数",
    "deposit-extend-count": "存款时延长·计数",
    "checkout-extend-count": "结帐时延长计数",
    "cancelled-extend-count": "取消时延长计数",
    disabled: "未启用",
    rank: "级",
    "current-balance": "当前余额",
    "adjustment-amount": "调整金额",
    "adjusted-balance": "调整后余额",
    adjust: "调整",
    reason: "原因",
    cash: "现金",
    expand: "扩张",
    "add-order": "新增订单",
    upload: "上传",
    "comment-message": "评论信息",
    "session-no": "会话代号",
    postage: "邮资",
    shipment: "运输",
    "product-price": "产品价格",
    "product-weight-g": "产品重量（g）",
    "expiration-date": "到期于",
    reverse: "撤销",
    "allowed-delivery-methods": "允许的运送方式",
    "standard-product-prices": "标准产品价格",
    unnamed: "未命名",
    "contact-us": "联系我们",
    "registration-name": "注册名称",
    "our-store": "我们的商店",
    "our-facebook-page": "我们的Facebook页面",
	"email-address": "电子邮件",
	"comment-info": "评论详情",
	"search-comment": "搜索评论",
    "login-history": "登录记录",
    "login-date": "登录日期",
    "login-source": "登录源",
	exclude: "排除",
    standard: "标准",
    facebook: "Facebook",
    streamer: "主播",
    "auto-reload": "自动刷新",
    "comment-date": "评论日期",
    reply: "回复",
    pending: "待定",
    "record-date": "日期",
	psid: "PSID",
	"request-by-psid": "请求于PSID",
	"transfer-to-psid": "转让至PSID",
	"video-selection": "视频选项",
	"live-videos": "直播视频",
	"prev-videos": "过往视频",
	"exclude-linked": "排除已连接",
	"preset-product": "预置产品",
	"conversation-url": "对话地址",
	navigating: "导航中",
	navigate: "导航",
	"name-history": "名称记录",
	export: "导出",
	"created-date": "创建日期",
	"exclude-accepted": "排除已接受",
	"more": "更多",
	"enabled": "启用",
	"rever-last-order": "还原最后订单",
	"notify-template": "通知模板",
	"notify-customer": "通知客户",
	"imtbot-access-token": "Imtbot访问令牌",
    "close-account": "关闭账号",
    "confirmation": "确认",
    "infinite": "无限"
};

/* ENDED TRANSLATION */
export { text }